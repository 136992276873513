import React, {Component} from 'react';
import ReactDOM from 'react-dom';
class  Educationalproducts extends React.Component {
    render() {
         return <div id="educational" class="tab-pane fade active show">
         <div class="row">
             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span>-20%</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-1.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-2.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Stylish Design Chair</a></h3>
                         <p class="product-price"><span class="discounted-price">$190.00</span> <span class="main-price discounted">$230.00</span></p>
                     </div>
                 </div>
               {/*   <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span>-20%</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-2.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-3.jpg" class="img-fluid" alt=""/>
                         </a>

                         {/* <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Miro Dining Table</a></h3>
                         <p class="product-price"><span class="discounted-price">$113.00</span> <span class="main-price discounted">$180.00</span></p>
                     </div>
                 </div>
               {/*   <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span>-20%</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-4.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-1.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Janus Table Lamp</a></h3>
                         <p class="product-price"><span class="discounted-price">$86.00</span> <span class="main-price discounted">$150.00</span></p>
                     </div>
                 </div>
                {/*  <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
              {/*    <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-3.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Discus Floor and Table</a></h3>
                         <p class="product-price"><span class="discounted-price">$290.00</span> <span class="main-price discounted">$330.00</span></p>
                     </div>
                 </div>
                 {/* <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                 {/* <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span class="sale">Sale</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-5.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-2.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Sled Mini Sideboard</a></h3>
                         <p class="product-price"><span class="discounted-price">$90.00</span></p>
                     </div>
                 </div>
              {/*    <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                 {/* <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span class="sale">New</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-6.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-4.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Mega 2 Seater Sofa</a></h3>
                         <p class="product-price"><span class="discounted-price">$390.00</span> <span class="main-price discounted">$470.00</span></p>
                     </div>
                 </div>
               {/*   <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span>-20%</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-7.jpg" class="img-fluid" alt=""/>
                         </a>

                         {/* <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Sentei Pruning Shears</a></h3>
                         <p class="product-price"><span class="discounted-price">$65.00</span> </p>
                     </div>
                 </div>
                {/*  <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span>-29%</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-8.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-2.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Affordances Side Table</a></h3>
                         <p class="product-price"><span class="discounted-price">$170.00</span> <span class="main-price discounted">$280.00</span></p>
                     </div>
                 </div>
               {/*   <!--  Single Grid product End --> */}
             </div>
         </div>
     </div>
        }
    }
    export default Educationalproducts;