import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './Brandlogo.css';
class Brandlogo extends React.Component {
    render() {
         return <div>
<div class="brand-section section pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-flex justify-center">
                <div class="col">
                  
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-1.png" alt=""/>
                        </div>
                    </div>
                 
                </div>
                <div class="col">
                    
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-2.png" alt=""/>
                        </div>
                    </div>
                   
                </div>
                <div class="col">
                    
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-3.png" alt=""/>
                        </div>
                    </div>
                   
                </div>
                <div class="col">
                  
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-4.png" alt=""/>
                        </div>
                    </div>
                   
                </div>
                <div class="col">
                    
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-5.png" alt=""/>
                        </div>
                    </div>
                   
                </div>
                {/* <div class="col">
               
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-1.png" alt=""/>
                        </div>
                    </div>
              
                </div>
                <div class="col">
                  
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-2.png" alt=""/>
                        </div>
                    </div>
                   
                </div>
                <div class="col">
                    
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-3.png" alt=""/>
                        </div>
                    </div>
                   
                </div>
                <div class="col">
                 
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-4.png" alt=""/>
                        </div>
                    </div>
                   
                </div>
                <div class="col">
                  
                    <div class="single-brand logo-size">
                        <div class="brand-image">
                            <img src="./assets/images/brands/brand-5.png" alt=""/>
                        </div>
                    </div>
               
                </div> */}
            </div>
        </div>
    </div>
</div>
         </div>
             }
            }
            export default Brandlogo;