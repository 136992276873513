
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumbs from '../Components/Breadcrumbs';


class Aboutus extends React.Component {
    render() {
         return <div>

<Breadcrumbs  title="About"  bc="About"/>
        <div class="about-us-area section pt-90 pt-lg-70 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-60 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                       {/*  <!--About Us Image Start--> */}
                        <div class="about-us-img-wrapper mb-30 mb-xs-15">
                            <div class="about-us-image img-full">
                                <a href="#"><img src="./assets/images/blog/blog-6.jpg" alt=""/></a>
                            </div>
                        </div>
                       {/*  <!--About Us Image End--> */}
                    </div>
                    <div class="col-lg-6 col-12">
                       {/*  <!--About Us Content Start--> */}
                        <div class="about-us-content pl-30 pl-md-0 pl-sm-0 pl-xs-0">
                            <span>Since 2019</span>
                            <h2>Provide Best Quality Furnitures for Our Cleint</h2>
                            <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam ?</p>
                            <p>consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam</p>
                        </div>
                       {/*  <!--About Us Content End--> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-section section pb-40 pb-sm-30 pb-xs-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                       {/*  <!-- Single Banner Start --> */}
                        <div class="single-banner-item pt-100 pt-md-80 pt-sm-70 pt-xs-50 pb-120 pb-md-100 pb-sm-90 pb-xs-50 mb-30 bg-image" data-bg="./assets/images/banner/banner3.jpg">
                            <div class="sp-banner-content">
                                <span class="normat-text">DISCOUNTED UP TO 50%</span>
                                <h2 class="title">Zigzag King Chair</h2>
                                <span class="normat-text">LIMITED TIME OFEER</span>
                                <div class="countdown-area">
                                    <div class="product-countdown" data-countdown="2019/06/01"></div>
                                </div>
                                <a href="shop.html">SHOP NOW</a>
                            </div>
                        </div>
                       {/*  <!-- Single Banner End --> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="features-section section pt-30 pt-lg-15 pt-md-0 pt-sm-0 pt-xs-15">
            <div class="container">
                <div class="row">

                    <div class="col-lg-4 col-md-6">
                       {/*  <!-- Single Feature Start --> */}
                        <div class="single-feature mb-30">
                            <div class="feature-image">
                                <img src="./assets/images/icons/feature-1.png" class="img-fluid" alt=""/>
                            </div>
                            <div class="feature-content">
                                <h4 class="title">Free home delivery</h4>
                                <p class="short-desc">Provide free home delivery for the all product over $100 </p>
                            </div>
                        </div>
                      {/*   <!-- Single Feature End --> */}
                    </div>
                    <div class="col-lg-4 col-md-6">
                        {/* <!-- Single Feature Start --> */}
                        <div class="single-feature mb-30">
                            <div class="feature-image">
                                <img src="./assets/images/icons/feature-2.png" class="img-fluid" alt=""/>
                            </div>
                            <div class="feature-content">
                                <h4 class="title">Quality Products</h4>
                                <p class="short-desc">We ensure the product quality that is our main goal </p>
                            </div>
                        </div>
                     {/*    <!-- Single Feature End --> */}
                    </div>
                    <div class="col-lg-4 col-md-6">
                        {/* <!-- Single Feature Start --> */}
                        <div class="single-feature mb-30">
                            <div class="feature-image">
                                <img src="./assets/images/icons/feature-3.png" class="img-fluid" alt=""/>
                            </div>
                            <div class="feature-content">
                                <h4 class="title">3 Days Return</h4>
                                <p class="short-desc">Provide free home delivery for the all product over $100 </p>
                            </div>
                        </div>
                     {/*    <!-- Single Feature End --> */}
                    </div>

                </div>
            </div>
        </div>
        <div class="about-us-team-area section pt-65 pt-lg-45 pt-md-35 pt-sm-20 pt-xs-15 pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="section-title text-center mb-40 mb-xs-20">
                            <h2>Our Team</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-6">
                       {/*  <!--Single Team Start--> */}
                        <div class="single-team mb-30">
                            <div class="team-image img-full">
                                <a href="#"><img src="./assets/images/team/team-1.jpg" alt=""/></a>
                            </div>
                            <div class="team-content">
                                <div class="team-hover-info">
                                    <h2><a href="#">Marcos Alonso</a></h2>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                       {/*  <!--Single Team End--> */}
                    </div>
                    <div class="col-md-4 col-sm-6">
                        {/* <!--Single Team Start--> */}
                        <div class="single-team mb-30">
                            <div class="team-image img-full">
                                <a href="#"><img src="./assets/images/team/team-2.jpg" alt=""/></a>
                            </div>
                            <div class="team-content">
                                <div class="team-hover-info">
                                    <h2><a href="#">Isaac Newton</a></h2>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                      {/*   <!--Single Team End--> */}
                    </div>
                    <div class="col-md-4 col-sm-6">
                       {/*  <!--Single Team Start--> */}
                        <div class="single-team mb-30">
                            <div class="team-image img-full">
                                <a href="#"><img src="./assets/images/team/team-3.jpg" alt=""/></a>
                            </div>
                            <div class="team-content">
                                <div class="team-hover-info">
                                    <h2><a href="#">Charlotte Taylor</a></h2>
                                    <ul class="team-social">
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                     {/*    <!--Single Team End--> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="testimonial-section section pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="testimonial-wrap bg-gray-two pt-45 pb-30">
                            <div class="row justify-content-center">
                                <div class="col-lg-12">
                                    <div class="testimonial-wrapper section-space--inner">
                                        <div class="testimonial-slider-content">
                                            <div class="item">
                                                <div class="row align-items-center">
                                                    <div class="col-md-5">
                                                        <div class="testimonial-image">
                                                            <img src="./assets/images/testimonial/testimonial-2.png" alt=""/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="testimonial testimonial-style-2 gutter-item">
                                                            <div class="testimonial-inner">
                                                                <div class="testimonial-author">
                                                                    <div class="author-thumb">
                                                                        <img src="assets/images/author/author-1.png" alt=""/>
                                                                    </div>
                                                                    <div class="author-info">
                                                                        <h4>Zeniyea Henderson</h4>
                                                                        <span>CTO & CO Founder, Axels</span>
                                                                    </div>
                                                                </div>
                                                                <div class="testimonial-description">
                                                                    <blockquote class="testimonials-text">
                                                                        <p>“I am very much happy to buy product from nelson, the provide the best quality of product. Product quality is very satisfactory. Also the creative design of their Furniture make me happy.”</p>
                                                                    </blockquote>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="item">
                                                <div class="row align-items-center">
                                                    <div class="col-md-5">
                                                        <div class="testimonial-image">
                                                            <img src="./assets/images/testimonial/testimonial-1.png" alt=""/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="testimonial testimonial-style-2 gutter-item">
                                                            <div class="testimonial-inner">
                                                                <div class="testimonial-author">
                                                                    <div class="author-thumb">
                                                                        <img src="assets/images/author/author-1.png" alt=""/>
                                                                    </div>
                                                                    <div class="author-info">
                                                                        <h4>Zeniyea Henderson</h4>
                                                                        <span>CTO & CO Founder, Axels</span>
                                                                    </div>
                                                                </div>
                                                                <div class="testimonial-description">
                                                                    <blockquote class="testimonials-text">
                                                                        <p>“I am very much happy to buy product from nelson, the provide the best quality of product. Product quality is very satisfactory. Also the creative design of their Furniture make me happy.”</p>
                                                                    </blockquote>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
         }
        }
        export default Aboutus;