import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import  './Banner.css';
class Banner2 extends React.Component {
    render() {
         return <div>
<div class="banner-section section img ">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
               {/*  <!-- Single Banner Start --> */}
                <div class="single-banner-item mb-30">
                    <div class="banner-image">
                        <a href="shop.html">
                            <img src="./assets/images/banner/banner1.jpg" alt=""/>
                        </a>
                    </div>
                    <div class="banner-content">
                        <h3 class="title">OFFICE <br/> FURNITURE</h3>
                            <a href="shop.html">SHOP NOW</a>
                    </div>
                </div>
               {/*  <!-- Single Banner End --> */}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                {/* <!-- Single Banner Start --> */}
                <div class="single-banner-item mb-30">
                    <div class="banner-image">
                        <a href="shop-left-sidebar.html">
                            <img src="./assets/images/banner/banner2.jpg" alt=""/>
                        </a>
                    </div>
                     <div class="banner-content tr-right">
                        <h3 class="title">HOME <br/> FURNITURE</h3>
                            <a href="shop.html">SHOP NOW</a>
                    </div> 
                </div>
               {/*  <!-- Single Banner End --> */}
            </div>
        </div>
    </div>
</div>
         </div>
          }
        }
        export default Banner2;