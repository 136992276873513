import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import  './Slickdots.css';
import Slider from "react-slick";

class Banner1 extends React.Component {
    render() {
          var settings = {
            slidesToShow: 1,
            fade: true,
            speed: 500,
            slidesToScroll: 1,
            fade: true,
            loop: true,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
          
        }
         return <div>

<div class=" dot-none hero-section section position-relative">
            <div class="hero-slider section">
 <Slider {...settings}>
       {/*  <!--Hero Item start--> */}
        <div class="hero-item  bg-image " ><img src="/assets/images/hero/hero-1.jpg"/>
            <div class="container">
                <div class="row">
                    <div class="col-12">

                      {/*   <!--Hero Content start--> */}
                      {/*   <div class="hero-content-2 center">

                            <h2>Creative Design <br/> Modern & Exclusive Furniture</h2>
                                <a href="shop.html" class="btn">SHOP NOW</a>

                        </div> */}
                     {/*    <!--Hero Content end-->
 */}
                    </div>
                </div>
            </div>
        </div>
        {/* <!--Hero Item end-->

        <!--Hero Item start--> */}
        <div class="hero-item bg-image next-slick-arrow"><img src="/assets/images/hero/hero-2.jpg"/>
            <div class="container">
                <div class="row">
                    <div class="col-12">

                        {/* <!--Hero Content start--> */}
                        {/* <div class="hero-content-2 center ">

                            <h2>Creative Design <br/> Modern & Exclusive Furniture</h2>
                                <a href="shop.html" class="btn">SHOP NOW</a>

                        </div> */}
                       {/*  <!--Hero Content end--> */}

                    </div>
                </div>
            </div>
        </div>
     {/*    <!--Hero Item end--> */}
     </Slider>
     
    </div>
</div>
         </div>
           }
        }
        export default Banner1;