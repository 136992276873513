import React, {Component} from 'react';
import ReactDOM from 'react-dom';
class  Commercialproducts extends React.Component {
    render() {
         return<div id="commercial" class="tab-pane">
         <div class="row">
             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-9.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-10.jpg" class="img-fluid" alt=""/>
                         </a>
{/* 
                         <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Normal Dining chair</a></h3>
                         <p class="product-price"><span class="discounted-price">$130.00</span> </p>
                     </div>
                 </div>
                {/*  <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
               {/*   <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span class="sale">Sale</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-11.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-12.jpg" class="img-fluid" alt=""/>
                         </a>

                       {/*   <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Tripod lampshade</a></h3>
                         <p class="product-price"><span class="discounted-price">$210.00</span> <span class="main-price discounted">$240.00</span></p>
                     </div>
                 </div>
                {/*  <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                 {/* <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-10.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-13.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Hot Design Table</a></h3>
                         <p class="product-price"><span class="discounted-price">$250.00</span> <span class="main-price discounted">$280.00</span></p>
                     </div>
                 </div>
                {/*  <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                 {/* <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-14.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Outdoor Lock Chair</a></h3>
                         <p class="product-price"><span class="discounted-price">$180.00</span></p>
                     </div>
                 </div>
               {/*   <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
               {/*   <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <div class="product-label">
                             <span class="sale">New</span>
                         </div>
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-14.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-13.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Classic Chair</a></h3>
                         <p class="product-price"><span class="discounted-price">$60.00</span> </p>
                     </div>
                 </div>
               {/*   <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-15.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-16.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Classic Chair Wodden</a></h3>
                         <p class="product-price"><span class="discounted-price">$183.00</span> <span class="main-price discounted">$200.00</span></p>
                     </div>
                 </div>
                {/*  <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-10.jpg" class="img-fluid" alt=""/>
                         </a>

                        {/*  <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Classic Table Wodden</a></h3>
                         <p class="product-price"><span class="discounted-price">$290.00</span></p>
                     </div>
                 </div>
               {/*   <!--  Single Grid product End --> */}
             </div>

             <div class="col-lg-3 col-md-4 col-sm-6">
                {/*  <!--  Single Grid product Start --> */}
                 <div class="single-grid-product mb-40">
                     <div class="product-image">
                         <a href="product-details.html">
                             <img src="./assets/images/product/product-16.jpg" class="img-fluid" alt=""/>
                             <img src="./assets/images/product/product-2.jpg" class="img-fluid" alt=""/>
                         </a>
{/* 
                         <div class="product-action">
                             <ul>
                                 <li><a href="cart.html"><i class="fa fa-cart-plus"></i></a></li>
                                 <li><a href="#quick-view-modal-container" data-toggle="modal" title="Quick View"><i class="fa fa-eye"></i></a></li>
                                 <li><a href="wishlit.html"><i class="fa fa-heart-o"></i></a></li>
                             </ul>
                         </div> */}
                     </div>
                     <div class="product-content">
                         <h3 class="title"> <a href="product-details.html">Miniature Almari</a></h3>
                         <p class="product-price"><span class="discounted-price">$230.00</span> <span class="main-price discounted">$250.00</span></p>
                     </div>
                 </div>
                {/*  <!--  Single Grid product End --> */}
             </div>
         </div>
     </div>
            }
        }
        export default Commercialproducts;