import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Residentialproducts from '../Components/Residentialproducts';
import Commercialproducts from '../Components/Commercialproducts';
import Educationalproducts from '../Components/Educationalproducts';
import Officeproducts from '../Components/Officeproducts';
class  Popularfurniture extends React.Component {
    render() {
         return <div>
<div class="product-section section pt-70 pt-lg-50 pt-md-40 pt-sm-30 pt-xs-20 pb-55 pb-lg-35 pb-md-25 pb-sm-15 pb-xs-5">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="section-title text-center mb-15">
                    <h2>Popular Furniture</h2>
                </div>
                <div class="product-tab mb-50 mb-sm-30 mb-xs-20">
                    <ul class="nav">
                        <li><a class="active show" data-toggle="tab" href="#residential">Residential</a></li>
                        <li><a data-toggle="tab" href="#commercial">Commercial</a></li>
                        <li><a data-toggle="tab" href="#educational">Educational</a></li>
                        <li><a data-toggle="tab" href="#office">Office</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tab-content">
            <Residentialproducts/>
            <Commercialproducts/>
            <Educationalproducts/>
            <Officeproducts/>
        </div>


    </div>
</div>

         </div>
           }
        }
        export default Popularfurniture;