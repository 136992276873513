import React, {Component} from 'react';
import ReactDOM from 'react-dom';
class Loginregister extends React.Component {
    render() {
         return <div class="page-banner-section section bg-image" data-bg="assets/images/bg/breadcrumb.png">
            <div class="container">
                <div class="row">
                    <div class="col">

                        <div class="page-banner text-left">
                            <h2>Login Register</h2>
                            <ul class="page-breadcrumb">
                                <li><a href="index.html">Home</a></li>
                                <li>Login Register</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div class="login-register-section section pt-90 pt-lg-70 pt-md-60 pt-sm-55 pt-xs-45  pb-70 pb-lg-50 pb-md-40 pb-sm-30 pb-xs-20">
            <div class="container">
                <div class="row">
                  {/*   <!--Login Form Start--> */}
                    <div class="col-md-6 col-sm-6">
                        <div class="customer-login-register">
                            <div class="form-login-title">
                                <h2>Login</h2>
                            </div>
                            <div class="login-form">
                                <form action="#">
                                    <div class="form-fild">
                                        <p><label>Username or email address <span class="required">*</span></label></p>
                                        <input name="username" value="" type="text"/>
                                    </div>
                                    <div class="form-fild">
                                        <p><label>Password <span class="required">*</span></label></p>
                                        <input name="password" value="" type="password"/>
                                    </div>
                                    <div class="login-submit">
                                        <button type="submit" class="btn">Login</button>
                                        <label>
                                            <input class="checkbox" name="rememberme" value="" type="checkbox"/>
                                            <span>Remember me</span>
                                        </label>
                                    </div>
                                    <div class="lost-password">
                                        <a href="#">Lost your password?</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <!--Login Form End-->
                    <!--Register Form Start--> */}
                    <div class="col-md-6 col-sm-6">
                        <div class="customer-login-register register-pt-0">
                            <div class="form-register-title">
                                <h2>Register</h2>
                            </div>
                            <div class="register-form">
                                <form action="#">
                                    <div class="form-fild">
                                        <p><label>Username or email address <span class="required">*</span></label></p>
                                        <input name="username" value="" type="text"/>
                                    </div>
                                    <div class="form-fild">
                                        <p><label>Password <span class="required">*</span></label></p>
                                        <input name="password" value="" type="password"/>
                                    </div>
                                    <div class="register-submit">
                                        <button type="submit" class="btn">Register</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                   {/*  <!--Register Form End--> */}
                </div>
            </div>
        </div>
         </div>
         
           }
        }
        export default Loginregister;