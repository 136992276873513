import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import { Link } from 'react-router-dom';
import  './Slickdots.css';
class menubar extends React.Component {
    /*  constructor(){
        super();
        this.state = {
            menu : []
        }
    }
    componentDidMount(){
        alert("done")
    axios.get(process.env.REACT_APP_API_URL + 'api/method/xzpace.api.main_groups',{
        headers : {'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({menu:response.data['message']})
         console.log(response.data['message'])
    })
    .catch(err=>{console.log(err)})
    
     
    
    
    
    
    
    } */
    render() {
         return <div class="col-xl-7 col-lg-7 col-md-5 d-none d-lg-block col-12">

 <div class="header-menu-area text-center">
                                <nav class="main-menu">
                                    <ul>
                                        
                                            
                                    
										
										
										<li><a href="/shop-list">Furniture</a>
                                            <ul class="sub-menu">
                                                <li><a href="/shop-list">Residential</a>
												      {/* <ul class="sub-menu">
													     <li><a href="shop.html">Residential 1</a></li>
														  <li><a href="shop.html">Residential 2</a></li>
														  <li><a href="shop.html">Residential 3</a></li> 
													  </ul> */}
												 </li>
												 
												 <li><a href="/shop-list">Commercial</a>
												      {/* <ul class="sub-menu">
													       <li><a href="shop.html">Commercial 1</a></li>
														  <li><a href="shop.html">Commercial 2</a></li>
														  <li><a href="shop.html">Commercial 3</a></li> 
													  </ul> */}
												 </li>
												 
												 <li><a href="/shop-list">Educational</a>
												      {/* <ul class="sub-menu">
													      <li><a href="shop.html">Educational 1</a></li>
														  <li><a href="shop.html">Educational 2</a></li>
														  <li><a href="shop.html">Educational 3</a></li> 
													  </ul> */}
												 </li>
												 
												 <li><a href="/shop-list">Office</a>
												      {/* <ul class="sub-menu">
													      <li><a href="shop.html">Office 1</a></li>
														  <li><a href="shop.html">Office 2</a></li>
														  <li><a href="shop.html">Office 3</a></li> 
													  </ul> */}
												 </li>
												 
                                             
                                            </ul>
                                        </li>
										
										
										<li><a href="#">Products</a></li>
										
                                     
                                               
                                              
                                               
                                      
                                        
                                    </ul>
                                </nav>
                            </div>

                </div>
         
          }
        }
        export default menubar;