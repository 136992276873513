import React, {Component} from 'react';
import ReactDOM from 'react-dom';
class Footer extends React.Component {
    render() {
         return <div>
             <div class="newsletter-section section bg-gray-two pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-95 pb-lg-75 pb-md-65 pb-sm-60 pb-xs-50">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="newsletter-content">
                    <h2>Subscribe Our Newsletter</h2>
                    <p>Subscribe Today for free and save 10% on your first purchase.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="newsletter-wrap">
                    <div class="newsletter-form">
                        <form id="mc-form" class="mc-form">
                            <input type="email" placeholder="Enter Your Email Address Here..." required/>
                            <button type="submit" value="submit">SUBSCRIBE!</button>
                        </form>

                    </div>
                   {/*  <!-- mailchimp-alerts Start --> */}
                   {/*  <div class="mailchimp-alerts">
                        <div class="mailchimp-submitting"></div> mailchimp-submitting end 
                        <div class="mailchimp-success"></div> mailchimp-success end 
                        <div class="mailchimp-error"></div>mailchimp-error end 
                    </div> */}
                   {/*  <!-- mailchimp-alerts end --> */}
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="footer-section section bg-gray">

   {/*  <!--Footer Top start--> */}
    <div class="footer-top section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-55 pb-lg-35 pb-md-25 pb-sm-15 pb-xs-10">
        <div class="container">
            <div class="row">

                {/* <!--Footer Widget start--> */}
                <div class="footer-widget col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-40 mb-xs-35">
                    <h4 class="title"><span class="text">Opening Time</span></h4>
                    <p class="mb-15">Mon – Fri: 8AM – 10PM</p>
                    <p class="mb-15">Sat: 9AM-8PM</p>
                    <p class="mb-15">Sun: Closed</p>
                    <h4 class="opeaning-title">We Work All The Holidays</h4>
                </div>
                {/* <!--Footer Widget end-->


                <!--Footer Widget start--> */}
                <div class="footer-widget col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-40 mb-xs-35">
                    <h4 class="title"><span class="text">Quick Link</span></h4>
                    <ul class="ft-menu">
                        <li><a href="my-account.html">My account</a></li>
                        <li><a href="#">Wishlist</a></li>
                        <li><a href="#">Order Tracking</a></li>
                        <li><a href="privacy-policy.html">Privacy Policy</a></li>
                        <li><a href="#">Shipping Information</a></li>
                    </ul>
                </div>
                {/* <!--Footer Widget end-->

                <!--Footer Widget start--> */}
                <div class="footer-widget col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-40 mb-xs-35">
                    <h4 class="title"><span class="text">Information</span></h4>
                    <ul class="ft-menu">
                        <li><a href="about-us.html">About Us</a></li>
                        <li><a href="#">Shopping Guide</a></li>
                        <li><a href="#">Delivery Information</a></li>
                        <li><a href="privacy-policy.html">Privacy Policy</a></li>
                        <li><a href="our-stores.html">Our Store</a></li>
                    </ul>
                </div>
                {/* <!--Footer Widget end-->

                <!--Footer Widget start--> */}
                <div class="footer-widget text-lg-right text-left col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-40 mb-xs-35">
                    <h2>123  Street Dubai, UAE</h2>
                    <p class="contact-text">info@xzpace.com</p>
                    <p class="contact-text">+0123456789</p>
                </div>
                {/*  <!-- */}Footer Widget end{/* --> */}
            </div>
        </div>
    </div>
   {/*  <!--Footer Top end-->

    <!--Footer bottom start--> */}
    <div class="footer-bottom section">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-12 ft-border pt-25 pb-25">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="copyright text-left">
                                <p>Copyright &copy;2021 <a href="#">Xzpace</a>. All rights reserved.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="payment-getway text-lg-right text-center">
                                <img src="./assets/images/payment.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/*     <!--Footer bottom end--> */}

</footer>
</div>
    }
}
export default Footer;