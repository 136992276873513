import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router, Route, Switch, useParams, HashRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Home from './Pages/Home';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Myaccount from './Pages/Myaccount';
import Aboutus from './Pages/Aboutus';
import Productdetails from './Pages/Productdetails';
import Contactus from './Pages/Contactus';
import Checkout from './Pages/Checkout';
import Loginregister from './Pages/Loginregister';
import Shop from './Pages/Shop';
import Blogdetails from './Pages/Blogdetails';

ReactDOM.render(
  <React.StrictMode>
      <Router >
        <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/index">
              <Home />
            </Route>
            <Route exact path="/index.html">
              <Home />
            </Route>
            <Route exact path="/shop-list">
              <Shop />
            </Route>
            <Route exact path="/my-account.html">
              <Myaccount />
            </Route>
            <Route exact path="/contact-us.html">
              <Contactus />
            </Route>
            <Route exact path="/blog-details.html">
              <Blogdetails />
            </Route>
            <Route exact path="/checkout.html">
              <Checkout />
            </Route>
            <Route exact path="/product-details/:slug1">
              <Productdetails/>
            </Route>
            <Route exact path="/login-register.html">
              <Loginregister/>
            </Route>
            <Route exact path="/about-us.html">
              <Aboutus head="About" />
            </Route>
            <Route exact path="/privacy-policy.html">
              <Aboutus head="Privacy Policy"  />
            </Route>
            <Route exact path="/our-stores.html">
              <Aboutus head="Our Stores"  />
            </Route>
          </Switch>
        <Footer />
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
