import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Authorreview from '../Components/Authorreview';
import Banner1 from '../Components/Banner1';
import Banner2 from '../Components/Banner2';
import Banner3 from '../Components/Banner3';
import Brandlogo from '../Components/Brandlogo';
import Footer from '../Components/Footer';
import Freehomedelivery from '../Components/Freehomedelivery';
import Header from '../Components/Header';
import Homeblog from '../Components/Homeblog';
import Latestpost from '../Components/Homeblog';
import Popularfurniture from '../Components/Popularfurniture';

class Home extends React.Component {
    render() {
         return <div id="main-wrapper">

            <Banner1/>
            <Banner2/>
            <Popularfurniture />
            <Banner3/>
            <Freehomedelivery/>
            <Homeblog/>
            <Authorreview/>
            <Brandlogo/>
            

</div>

             }
            }
            export default Home;