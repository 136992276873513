import React, {Component} from 'react';
import ReactDOM from 'react-dom';

class Breadcrumbs extends React.Component {
    render() {
        return <div>
<div class="page-banner-section section bg-image" >
    <img src="/assets/images/bg/breadcrumb.png"/>
            <div class="container">
                <div class="row">
                    <div class="col br-cr-head">

                        <div class="page-banner text-left">
                            <h2>{this.props.title}</h2>
                            <ul class="page-breadcrumb">
                                <li><a href="index.html">Home</a></li>
                                <li>{this.props.bc}</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
</div>
         }
        }
        export default Breadcrumbs;