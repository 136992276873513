import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Menubar from './Menubar';
import  './Slickdots.css';
class Header extends React.Component {
   
    render() {
         return <div>

<header class="header header-transparent header-sticky  d-lg-block d-none pos-sticky">
    <div class="header-deafult-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-2 col-lg-2 col-md-3 col-12">
                   {/*  <!--Logo Area Start--> */}
                    <div class="logo-area">
                        <a href="index.html"><img src="/assets/images/logo.svg" alt=""/></a>
                    </div>
                    {/* <!--Logo Area End--> */}
                </div>
                <Menubar/>
                <div class="col-xl-3 col-lg-4 col-md-5 col-12">
                   {/*  <!--Header Search And Mini Cart Area Start--> */}
                    <div class="header-search-cart-area">
                        <ul>
                            <li><a class="header-search-toggle" href="#"><i class="flaticon-magnifying-glass"></i></a></li>
                            <li class="currency-menu"><a href="#"><i class="flaticon-user m-1"></i></a>
                               {/*  <!--Crunccy dropdown--> */}
                                <ul class="currency-dropdown">
                                  {/*   <!--Language Currency Start--> */}
                                   {/* <li><a href="#">language</a>
                                        <ul>
                                            <li class="active"><a href="#"><img src="./assets/images/icons/en-gb.png" alt=""/>English</a></li>
                                            <li><a href="#"><img src="./assets/images/icons/de-de.png" alt=""/>French</a></li>
                                        </ul>
                                    </li> */}
                                    {/* <!--Language Currency End-->
                                    <!--USD Currency Start--> */}
                                    {/* <li><a href="#">Currency</a>
                                        <ul>
                                            <li><a href="#"> € Euro</a></li>
                                            <li><a href="#"> $ US Dollar</a></li>
                                        </ul>
                                    </li> */}
                                  {/*   <!--USD Currency End-->
                                    <!--Account Currency Start--> */}
                                    <li><a href="my-account.html">My account</a>
                                        <ul>
                                            <li><a href="login-register.html">Login</a></li>
                                            <li><a href="checkout.html">Checkout</a></li>
                                            <li><a href="my-account.html">My account</a></li>
                                            <li><a href="cart.html">Cart</a></li>
                                            <li><a href="wishlist.html">Wishlist</a></li>
                                        </ul>
                                    </li>
                                  {/*   <!--Account Currency End--> */}
                                </ul>
                               {/*  <!--Crunccy dropdown--> */}
                            </li>
                            <li class="mini-cart"><a href="#"><i class="flaticon-shopping-cart m-1"></i> <span class="mini-cart-total fnt-cart-price">$300.00(2)</span></a>
                               {/*  <!--Mini Cart Dropdown Start--> */}
                                <div class="header-cart">
                                    <ul class="cart-items">
                                        <li class="single-cart-item">
                                            <div class="cart-img">
                                                <a href="cart.html"><img src="./assets/images/cart/cart1.jpg" alt=""/></a>
                                            </div>
                                            <div class="cart-content">
                                                <h5 class="product-name"><a href="single-product.html">Dell Inspiron 24</a></h5>
                                                <span class="product-quantity">1 ×</span>
                                                <span class="product-price">$278.00</span>
                                            </div>
                                            <div class="cart-item-remove">
                                                <a title="Remove" href="#"><i class="fa fa-trash"></i></a>
                                            </div>
                                        </li>
                                        <li class="single-cart-item">
                                            <div class="cart-img">
                                                <a href="cart.html"><img src="./assets/images/cart/cart2.jpg" alt=""/></a>
                                            </div>
                                            <div class="cart-content">
                                                <h5 class="product-name"><a href="single-product.html">Lenovo Ideacentre 300</a></h5>
                                                <span class="product-quantity">1 ×</span>
                                                <span class="product-price">$23.39</span>
                                            </div>
                                            <div class="cart-item-remove">
                                                <a title="Remove" href="#"><i class="fa fa-trash"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="cart-total">
                                        <h5>Subtotal :<span class="float-right">$39.79</span></h5>
                                        <h5>Eco Tax (-2.00) :<span class="float-right">$7.00</span></h5>
                                        <h5>VAT (20%) : <span class="float-right">$0.00</span></h5>
                                        <h5>Total : <span class="float-right">$46.79</span></h5>
                                    </div>
                                    <div class="cart-btn">
                                        <a href="cart.html">View Cart</a>
                                        <a href="checkout.html">checkout</a>
                                    </div>
                                </div>
                              {/*   <!--Mini Cart Dropdown End--> */}
                            </li>
                        </ul>
                    </div>
                  {/*   <!--Header Search And Mini Cart Area End--> */}
                </div>
            </div>
        </div>
    </div>
</header>
{/* <!--Header section end--> */}

{/* <!--Header Mobile section start--> */}
<header class="header-mobile d-block d-lg-none">
    <div class="header-bottom menu-right">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="header-mobile-navigation d-block d-lg-none">
                        <div class="row align-items-center">
                            <div class="col-6 col-md-6">
                                <div class="header-logo">
                                    <a href="index.html">
                                        <img src="./assets/images/logo.svg" class="img-fluid" alt=""/>
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="mobile-navigation text-right">
                                    <div class="header-icon-wrapper">
                                        <ul class="icon-list justify-content-end">
                                            <li>
                                                <div class="header-cart-icon">
                                                    <a href="cart.html"><i class="flaticon-shopping-cart"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)" class="mobile-menu-icon" id="mobile-menu-trigger"><i class="fa fa-bars"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          {/*   <!--Mobile Menu start--> */}
            <div class="row">
                <div class="col-12 d-flex d-lg-none">
                    <div class="mobile-menu"></div>
                </div>
            </div>
           {/*  <!--Mobile Menu end--> */}

        </div>
    </div>
</header>
{/* <!-- Offcanvas Menu Start --> */}
        <div class="offcanvas-mobile-menu d-block d-lg-none" id="offcanvas-mobile-menu">
            <a href="javascript:void(0)" class="offcanvas-menu-close" id="offcanvas-menu-close-trigger">
                <i class="fa fa-times"></i>
            </a>

            <div class="offcanvas-wrapper">

                <div class="offcanvas-inner-content">
                    <div class="offcanvas-mobile-search-area">
                        <form action="#">
                            <input type="search" placeholder="Search ..."/>
                            <button type="submit"><i class="fa fa-search"></i></button>
                        </form>
                    </div>
                    <nav class="offcanvas-navigation">
                        <ul>
                            <li class="menu-item-has-children"><a href="#">Furniture</a>
                                <ul class="submenu2">
								   <li class="menu-item-has-children"><a href="#">Residential</a>
								      <ul class="submenu2">
                                        <li><a href="/shop-list">Residential 1</a></li>
									    <li><a href="/shop-list">Residential 2</a></li>
									    <li><a href="/shop-list">Residential 3</a></li>
									  </ul>
								   </li>
                                </ul>
								
								 <ul class="submenu2">
								   <li class="menu-item-has-children"><a href="#">Commercial</a>
								      <ul class="submenu2">
                                        <li><a href="shop.html">Commercial 1</a></li>
									    <li><a href="shop.html">Commercial 2</a></li>
									    <li><a href="shop.html">Commercial 3</a></li>
									  </ul>
								   </li>
                                </ul>
								
								 <ul class="submenu2">
								   <li class="menu-item-has-children"><a href="#">Educational</a>
								      <ul class="submenu2">
                                        <li><a href="shop.html">Educational 1</a></li>
									    <li><a href="shop.html">Educational 2</a></li>
									    <li><a href="shop.html">Educational 3</a></li>
									  </ul>
								   </li>
                                </ul>
								
								<ul class="submenu2">
								   <li class="menu-item-has-children"><a href="#">Office</a>
								      <ul class="submenu2">
                                        <li><a href="shop.html">Office 1</a></li>
									    <li><a href="shop.html">Office 2</a></li>
									    <li><a href="shop.html">Office 3</a></li>
									  </ul>
								   </li>
                                </ul>
								
								
                            </li>
							
							<li><a href="#">Interiors</a></li>
							<li><a href="#">Designers</a></li>
							<li><a href="#">Contact</a></li>
							<li><a href="#">Construction</a></li>
							<li><a href="#">Products</a></li>
							<li><a href="#">Contact</a></li>
                                                

                        </ul>
                    </nav>

                    <div class="offcanvas-settings">
                        <nav class="offcanvas-navigation">
                            <ul>
                                <li class="menu-item-has-children"><a href="#">MY ACCOUNT </a>
                                    <ul class="submenu2">
                                        <li><a href="login-register.html">Login</a></li>
                                        <li><a href="checkout.html">Checkout</a></li>
                                        <li><a href="my-account.html">My account</a></li>
                                        <li><a href="cart.html">Cart</a></li>
                                        <li><a href="wishlist.html">Wishlist</a></li>
                                    </ul>
                                </li>
                                <li class="menu-item-has-children"><a href="#">CURRENCY: USD </a>
                                    <ul class="submenu2">
                                        <li><a href="javascript:void(0)">€ Euro</a></li>
                                        <li><a href="javascript:void(0)">$ US Dollar</a></li>
                                    </ul>
                                </li>
                                <li class="menu-item-has-children"><a href="#">LANGUAGE: EN-GB </a>
                                    <ul class="submenu2">
                                        <li><a href="javascript:void(0)"><img src="./assets/images/icons/en-gb.png" alt=""/> English</a></li>
                                        <li><a href="javascript:void(0)"><img src="./assets/images/icons/de-de.png" alt=""/> Germany</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="offcanvas-widget-area">
                        <div class="off-canvas-contact-widget">
                            <div class="header-contact-info">
                                <ul class="header-contact-info-list">
                                    <li><i class="ion-android-phone-portrait"></i> <a href="tel://12452456012">(1245) 2456 012 </a></li>
                                    <li><i class="ion-android-mail"></i> <a href="mailto:info@yourdomain.com">info@yourdomain.com</a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!--Off Canvas Widget Social Start--> */}
                        <div class="off-canvas-widget-social">
                            <a href="#" title="Facebook"><i class="fa fa-facebook"></i></a>
                            <a href="#" title="Twitter"><i class="fa fa-twitter"></i></a>
                            <a href="#" title="LinkedIn"><i class="fa fa-linkedin"></i></a>
                            <a href="#" title="Youtube"><i class="fa fa-youtube-play"></i></a>
                            <a href="#" title="Vimeo"><i class="fa fa-vimeo-square"></i></a>
                        </div>
                        {/* <!--Off Canvas Widget Social End--> */}
                    </div>
                </div>
            </div>

        </div>
        {/* <!-- Offcanvas Menu End -->

        <!-- main-search start --> */}
        <div class="main-search-active">
            <div class="sidebar-search-icon">
                <button class="search-close"><i class="fa fa-times"></i></button>
            </div>
            <div class="sidebar-search-input">
                <form action="#">
                    <div class="form-search">
                        <input id="search" class="input-text" value="" placeholder="" type="search"/>
                        <button>
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </form>
                <p class="form-description">Hit enter to search or ESC to close</p>
            </div>
        </div>
        {/* <!-- main-search start --> */}
</div>
            

        }
    }
    export default Header;